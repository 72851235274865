"use client";
import Link from "next/link";
import {
  UnkeyFooterLogo,
  UnkeyFooterLogoMobile,
  UnkeyLogoSmall,
  UnkeyLogoSmallMobile,
} from "./footer-svgs";
import { socialMediaProfiles } from "./social-media";
import { useSelectedLayoutSegment } from "next/navigation";
import { useTranslation } from "react-i18next";

// const navigation = [
//   {
//     title: "footer:connect", // 使用命名空间
//     links: [
//       { title: "footer:twitter", href: "https://twitter.com/JellyDropsLLC" },
//       { title: "footer:github", href: "https://github.com/JellyDropsLLC" },
//     ],
//   },
// ];

const navigation = [
  {
    title: "footer:connect", // 使用命名空间
    links: [
      { title: "footer:twitter", href: "https://twitter.com/JellyDropsLLC", icon: "/icons/twitter.svg" },
      { title: "footer:github", href: "https://github.com/JellyDropsLLC", icon: "/icons/github.svg" },
    ],
  },
];



function CompanyInfo() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col ">
      <div className="font-normal text-sm leading-6 text-[rgba(255,255,255,0.5)] mt-8">
        {t('copyright')}
      </div>
      <div className="font-normal text-sm leading-6 text-[rgba(255,255,255,0.3)]">
        {t('policies')}
      </div>
    </div>
  );
}

function CompanyInfoMobile() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <div className="font-normal text-sm leading-6 text-[rgba(255,255,255,0.5)] mt-10">
        {t('policies')}
      </div>
      <div className="font-normal text-sm leading-6 text-[rgba(255,255,255,0.3)]">
        {t('copyright')}
      </div>
      <div className="mt-10">
        {navigation.map((section, index) => (
          <div key={index}>
            <h3 className="py-4 text-sm font-medium text-white">{t(section.title)}</h3>
            <ul className="text-sm text-[rgba(255,255,255,0.7)] font-normal">
              {section.links.map((link) => (
                <li key={link.href.toString()} className="py-1">
                  {link.href.startsWith("https://") ? (
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:text-[rgba(255,255,255,0.4)]"
                    >
                      {t(link.title)}
                    </a>
                  ) : (
                    <Link
                      href={link.href}
                      className="transition hover:text-[rgba(255,255,255,0.4)]"
                    >
                      {t(link.title)}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}


function Navigation() {
  const { t } = useTranslation();

  return (
    <nav className="sm:w-full">
      <ul className="flex flex-col flex-auto gap-16 text-left sm:flex-row sm:ml-auto sm:mr-4 sm:text-center justify-end mb-[3rem]">
        {navigation.map((section) => (
          <li key={section.title.toString()}>
            {/* <div className="text-sm font-medium tracking-wider text-white font-display">
              {t(section.title)}
            </div> */}
            <ul className="flex flex-row gap-8 mt-4">
              {section.links.map((link) => (
                <li key={link.href.toString()}>
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transition hover:opacity-75"
                  >
                    <img
                      src={link.icon}
                      alt={t(link.title)}
                      className="w-6 h-6"
                      style={{ filter: 'invert(1)' }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      {/* <Link
        href="/#index-target"
        className="transition text-[#fff] hover:text-[rgba(255,255,255,0.4)]"
      >
        {t('footer:backToTop')}
      </Link> */}
    </nav>
  );

}


// function Navigation() {
//   const { t } = useTranslation();

//   return (
//     <nav className=" sm:w-full mb-20">
//       <ul className="flex flex-col flex-auto gap-16 text-left sm:flex-row sm:mx-auto sm:text-center justify-start mb-[3rem]">
//         {navigation.map((section) => (
//           <li key={section.title.toString()}>
//             <div className="text-sm font-medium tracking-wider text-white font-display">
//               {t(section.title)}
//             </div>
//             <ul className="text-sm text-[rgba(255,255,255,0.7)] font-normal gap-2 flex  flex-row md:gap-8 mt-4 md:mt-8 ">
//               {section.links.map((link) => (
//                 <li key={link.href.toString()}>
//                   {link.href.startsWith("https://") ? (
//                     <a
//                       href={link.href}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="transition hover:text-[rgba(255,255,255,0.4)] "
//                     >
//                       {t(link.title)}
//                     </a>
//                   ) : (
//                     <Link
//                       href={link.href}
//                       className="transition  hover:text-[rgba(255,255,255,0.4)] "
//                     >
//                       {t(link.title)}
//                     </Link>
//                   )}
//                 </li>
//               ))}

//             </ul>
//           </li>
//         ))}

//       </ul>
//       <Link
//         href="/#index-target"
//         className="transition text-[#fff] hover:text-[rgba(255,255,255,0.4)] "
//       >
//         {t('footer:backToTop')}
//       </Link>
//     </nav>
//   );
// }

function MobileNavigation() {
  return (
    <nav className="flex flex-col sm:hidden">
      <div className="flex flex-col items-center justify-center w-full text-center mb-[2rem]">
        <CompanyInfoMobile />
      </div>
    </nav>
  );
}

export function Footer() {
  const segment = useSelectedLayoutSegment();

  let isOK = true
  if (segment && ["auth", "/auth/login/", "/auth/signup/", "new", "manage"].includes(segment)) {
    isOK = false
  }

  if (!isOK) {
    return (<></>)
  }
  return (
    <>
      <footer className="relative hidden pt-32 overflow-hidden border-none sm:block xl:pt-10 max-sm:pt-8 border-white/10 blog-footer-radial-gradient">
        <div className="container flex flex-col mx-auto">
          <div className="flex flex-row justify-between max-sm:flex-col sm:flex-col md:flex-row xl:gap-0 xxl:gap-0">
            {/* 去掉 gap 来更紧凑地排列 */}

            <div className="flex mb-8 lg:mx-auto max-sm:pl-12 max-sm:flex sm:flex-row sm:w-full sm:pl-28 xl:pl-14 md:w-5/12 shrink-0">
              <CompanyInfo />
            </div>

            <div className="flex w-full max-sm:pl-12 max-sm:pt-6 sm:pl-28 md:pl-18 lg:pl-6 max-sm:mb-8 items-center">
              {/* 使用 items-center 来确保垂直居中 */}
              <Navigation />
            </div>

          </div>
        </div>
      </footer>
      <MobileNavigation />
    </>
  );
  // return (
  //   <>
  //     <footer className="relative hidden pt-32 overflow-hidden border-none sm:block xl:pt-10 max-sm:pt-8 border-white/10 blog-footer-radial-gradient">
  //       <div className="container flex flex-col mx-auto">
  //         <div className="flex flex-row justify-center max-sm:flex-col sm:flex-col md:flex-row xl:gap-20 xxl:gap-48">
  //           <div className="flex mb-8 lg:mx-auto max-sm:pl-12 max-sm:flex sm:flex-row sm:w-full sm:pl-28 xl:pl-14 md:w-5/12 shrink-0 xxl:pl-28">
  //             <CompanyInfo />
  //           </div>
  //           <div className="flex w-full max-sm:pl-12 max-sm:pt-6 max-sm:mt-22 sm:pl-28 md:pl-18 lg:pl-6 max-sm:mb-8">
  //             <Navigation />
  //           </div>
  //         </div>
  //       </div>
  //     </footer>
  //     <MobileNavigation />
  //   </>
  // );
}
